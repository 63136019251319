import * as React from 'react'
import './index.scss'
import Layout from '../layouts'
import { graphql, Link } from 'gatsby'

const IndexPage = () => {
  return (
    <Layout>
      <div className="hero">
        <h1>Weclome</h1>
        <p>
          We design, build & deploy software. Please <Link to="/contact">contact us</Link> to check availability.
        </p>
      </div>
    </Layout>
  )
}

export default IndexPage

